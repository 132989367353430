<template>
  <!-- 城市热门地点推荐 -->
  <div>
    <!-- 搜索框 -->
    <van-search id="inputRefId" autofocus show-action v-model="value" placeholder="请输入搜索目的地" @input="map">
      <template #action>
        <div @click="choose(value)">确定</div>
      </template>
    </van-search>

    <!-- 热门地点 -->
    <div class="hot-cont" v-show="!value">
      <template v-if="0 in hotelHistory">
        <van-cell title="历史记录" icon="underway-o" class="blue">
          <template #default>
            <van-icon name="delete-o" @click="del" />
          </template>
        </van-cell>
        <van-grid class="grid-bk">
          <van-grid-item @click="choose(data)" :text="data" v-for="(data,i) in hotelHistory.slice(0,8)"
            :key="i" />
        </van-grid>
        <div class="gap"></div>
      </template>

      <template v-if="recommendList.hotBusinessList">
        <van-cell title="热门" icon="fire-o" class="red" />
        <van-grid class="grid-bk">
          <van-grid-item @click="choose(data.name)" :text="data.name"
            v-for="data in recommendList.hotBusinessList.filter(e=>e.name.length<5).slice(0,8).sort()" :key="data.id" />
        </van-grid>
        <div class="gap"></div>
      </template>

      <template v-if="recommendList.brandList">
        <van-cell title="品牌" icon="gem-o" class="violet" />
        <van-grid class="grid-bk">
          <van-grid-item @click="choose(data.name)" :text="data.name"
            v-for="data in recommendList.brandList.filter(e=>e.name.length<5).slice(0,8).sort()" :key="data.id" />
        </van-grid>
        <div class="gap"></div>
      </template>

      <template v-if="recommendList.trafficStationList">
        <van-cell title="交通" icon="logistics" class="blue" />
        <van-grid class="grid-bk">
          <van-grid-item @click="choose(data.name)" :text="data.name"
            v-for="data in recommendList.trafficStationList.filter(e=>e.name.length<9).slice(0,8).sort()"
            :key="data.id" />
        </van-grid>
        <div class="gap"></div>
      </template>

      <template v-if="recommendList.hotSceneryList">
        <van-cell title="景点" icon="flower-o" class="green" />
        <van-grid class="grid-bk">
          <van-grid-item @click="choose(data.name)" :text="data.name"
            v-for="data in recommendList.hotSceneryList.filter(e=>e.name.length<5).slice(0,8).sort()" :key="data.id" />
        </van-grid>
      </template>

    </div>

    <!-- 百度地图联想 -->
    <div class="map-cont" v-show="value">
      <van-cell class="auto-cell" :title="data.title" :label="data.address" v-for="data in list" :key="data.uid"
        @click="choose(data.title)">
        <template #icon>
          <van-icon class="van-cell__left-icon" v-if="data.tags&&data.tags[0]=='酒店'" name="hotel-o" />
          <van-icon class="van-cell__left-icon" v-else-if="data.tags&&data.tags[0]=='购物'" name="bag-o" />
          <van-icon class="van-cell__left-icon" v-else-if="data.tags&&data.tags[0]=='休闲娱乐'" name="music-o" />
          <van-icon class="van-cell__left-icon" v-else-if="data.tags&&data.tags[0]=='教育培训'" name="diamond-o" />
          <van-icon class="van-cell__left-icon" v-else-if="data.tags&&data.tags[0]=='文化传媒'" name="newspaper-o" />
          <van-icon class="van-cell__left-icon" v-else-if="data.tags&&data.tags[0]=='交通设施'" name="logistics" />
          <van-icon class="van-cell__left-icon" v-else name="location-o" />
        </template>
      </van-cell>
    </div>

  </div>
</template>

<script>
import { searchPosition } from "@/services/hotel";
import { getHotelFilterInfo } from "@/api/hotel";
import { mapGetters } from "vuex";
export default {
  name: "HotSpots",
  props: ["show"],
  computed: {
    ...mapGetters(["hotelOrderInfo"])
  },
  data() {
    return {
      value: "", // 搜索关键字
      list: [], // map查到的列表
      recommendList: {}, // 推荐列表
      hotelHistory: JSON.parse(localStorage.getItem("hotelHistory")) || [] // 历史记录
    };
  },
  mounted() {
    getHotelFilterInfo({
      corp: "大唐",
      city: this.hotelOrderInfo.city
    }).then(res => {
      this.recommendList = res;
    });
  },
  methods: {
    // 百度地图联想
    map() {
      searchPosition(this.value, this.hotelOrderInfo.city, (point, results) => {
        this.list = results.Hr;
      });
    },

    // 选择
    choose(data) {
      data = data.trim()
      // 插入历史记录 - 防止异常数据
      if(data){
        if(this.hotelHistory.includes(data)){
          this.hotelHistory.splice(this.hotelHistory.indexOf(data),1)
        }
        this.hotelHistory.unshift(data);
        localStorage.setItem("hotelHistory", JSON.stringify(this.hotelHistory));
      }
      this.hotelOrderInfo.keyword = data;
      this.$store.dispatch("hotel/setHotelOrder", this.hotelOrderInfo);
      this.$emit("close");
      // 动画完成后清空数据，300来源于vant样式变量@animation-duration-base
      setTimeout(() => {
        this.value = "";
        this.list = [];
      }, 300);
    },

    // 清空历史
    del() {
      localStorage.removeItem("hotelHistory");
      this.hotelHistory = [];
    }
  },
  watch: {
    show(newV, oldV) {
      if (newV && document.querySelector("#inputRefId"))
        document.querySelector("#inputRefId").focus();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../styles/theme.less";
.map-cont {
  height: calc(100vh - 56px);
  width: 80vw;
  background-color: @gray-2;
  overflow: scroll;
}
.hot-cont {
  width: 100%;
  height: calc(100vh - 56px);
  box-sizing: border-box;
  background-color: @gray-2;
  padding: 0.6rem;
  overflow: scroll;
}
.grid-bk {
  background-color: #fff;
}
</style>